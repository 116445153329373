<template>
    <div class="container news-container">
            <div v-if="condition.isLoadArticles" class="row">
                <preview-loader v-for="index in 3" :key="index"></preview-loader>
            </div>
        <div class="row" v-else-if="articles && articles.length > 0">
            <preview v-for="article in articles" :key="article.id"
                     :preview="article.previewImageUrl"
                     :isDisableForPartner="article.isDisableForPartner"
                    :slug="article.slug" :title="article.title" :description="article.description">
                {{article}}
            </preview>
        </div>
        <div v-else class="empty-notification__text">
            <p>Тут будуть з`являтися новини</p>
        </div>
        <div style="text-align: center">
            <pagination v-show="total > 0" :total="total"
                        :page.sync="listQuery.page"
                        :limit.sync="listQuery.limit" @pagination="initList()" />
        </div>
    </div>
</template>

<script>

import Pagination from '@/components/Pagination';
import preview from './components/preview';
import { list as articleList } from '@/api/article';
import { userViewedNews } from '@/api/news';
import previewLoader from './components/previewLoader';

export default {
    components: {
        previewLoader,
        preview,
        Pagination
    },
    data: function () {
        return {
            total: 10,
            condition: {
                isLoadArticles: true,
            },
            listQuery: {
                page: 1,
                limit: 10,
            },
            articles: {},
        }
    },
    created:  function() {
        articleList(this.listQuery).then((response) => {
            this.articles = response.data?.data?.items ?? [];
            this.total = response.data?.data?.total;
            // this.articles = response.data.data;
            this.condition.isLoadArticles = false;
        });
        userViewedNews();
        this.$store.commit('SET_COUNT_NOT_VIEWED_NEWS', 0);

    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
        initList: function () {
            articleList(this.listQuery).then((response) => {
                this.articles = response.data?.data?.items ?? [];
                this.total = response.data?.data?.total;
                // this.articles = response.data.data;
                this.condition.isLoadArticles = false;
            });
        }
    }
}
</script>
