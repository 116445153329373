<template>
    <div class="col-lg-4 col-md-6 mb-4">
        <div class="article">
            <a href="javascript:void(0)" class="preview" :style="'background-image:url('+ preview +')'"></a>
            <div class="info">
                <a :href="slug" >
                    <h3 class="title">{{ title }}</h3>
                </a>
                <p v-html="description" class="descr"></p>
                <a @click="show()" href="javascript:void(0)" class="article__show-more">
                    Читати далі
                </a>
                <div v-if="isDisableForPartner" class="article__alert">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8" r="8" fill="#FFDDDB"/>
                        <rect x="7" y="3" width="2" height="6" fill="#EF4236"/>
                        <rect x="7" y="11" width="2" height="2" fill="#EF4236"/>
                    </svg>
                    Ця новина не відображається для ваших партнерів
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        isDisableForPartner: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        slug: {
            type: String,
            default: () => {
                return null;
            }
        },
        title: {
            type: String,
            default: () => {
                return null;
            }
        },
        preview: {
            type: String,
            default: () => {
                return null;
            }
        },
        description: {
            type: String,
            default: () => {
                return null;
            }
        },
    },
    methods: {
        show: function() {
            this.$router.push({name: 'newShow', params: {slug: this.slug}})
        }
    }
}
</script>

<style lang="scss" scoped>
.article__show-more {
    display: block;
    width: 100%;
    padding: 7px 15px;
    border-radius: 3px;
    margin: auto auto 0;
    transition: all .2s;
    border: 1px solid #009beb;
    color: #009beb;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
}
.article {
    background: #fff;
    height: 100%;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 5%);
    position: relative;
    .title {
        font-size: 18px;
        color: #24282c;
        font-weight: 700;
        text-align: left;
        margin: 10px 0;
    }
    .info {
        padding: 20px;
        font-size: 15px;
        color: #999da6;
        text-align: left;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;

        .descr{
            word-break: break-all;
            p{
                word-break: break-all;
            }
        }

        .title h3 {
            font-size: 18px;
            color: #24282c;
            font-weight: 700;
            text-align: left;
            margin: 10px 0;
        }

        .short-content {
            color: #999da6;
            text-align: left;
            margin: 0 0 25px;
            font-size: 11pt;
            font-family: Arial;
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            vertical-align: baseline;
            white-space: pre-wrap;
        }
    }
}

.article__alert{
    position: absolute;
    top: 9px;
    left: 9px;
    background: #FFFFFF;
    border-radius: 30px;
    color: #999DA6;
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    svg{
        margin-right: 6px;
    }
    @media screen and (max-width: 1200px){
        font-size: 11px;
    }
    @media screen and (max-width: 359px){
        font-size: 9px;
    }
}

.truncate-overflow {
    --lh: 1.4rem;
    line-height: var(--lh);
    --max-lines: 3;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    padding-right: 1rem; /* space for ellipsis */
}
.truncate-overflow::before {
    position: absolute;
    content: "...";
    inset-block-end: 0; /* "bottom" */
    inset-inline-end: 0; /* "right" */
}
.truncate-overflow::after {
    content: "";
    position: absolute;
    inset-inline-end: 0; /* "right" */
    width: 1rem;
    height: 1rem;
    background: white;
}

.preview {
    min-height: 170px;
    height: 170px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    img {
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: right bottom;
        object-position: right bottom;
    }
}

</style>
